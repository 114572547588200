import enedisApiClient from '../enedis-api/enedis-api.client';
import salesforceGatewayClient from '../salesforce-gateway/salesforce-gateway.client';
import transportBudgetMapper, {
  TransportProfile,
} from '../TransportBudget.mapper';
import businessDataClient from './business-data.client';
import { MeasurementsImportParameters } from './business-data.interface';
import {
  AggregatedAnalysisOptions,
  Analysis,
  AnalysisSearchOptions,
  IndividualAnalysisOptions,
} from './dto/analysis.interface';
import {
  ExportMeasurementParameters,
  ExportReadingParameters,
} from './dto/measurement.interface';
import {
  FetchTransportPerimeter,
  PowerTransportPropertyType,
  TransportPerimeter,
} from './dto/power-transport.interface';

export class BusinessDataApiService {
  public async fetchTransports(
    perimeter: FetchTransportPerimeter
  ): Promise<TransportProfile[]> {
    const data = await businessDataClient.fetchTransports(perimeter);

    const profiles = await salesforceGatewayClient.fetchProfilEnergetiques(
      perimeter.prms
    );

    return transportBudgetMapper.buildTransportProfiles(
      perimeter,
      data,
      profiles
    );
  }

  public async downloadTemplateImportMeasurements(): Promise<{
    blob: Blob;
    filename?: string;
  }> {
    return businessDataClient.downloadTemplateImportMeasurements();
  }

  public async exportMeasurements(
    parameters: ExportMeasurementParameters
  ): Promise<void> {
    await businessDataClient.exportMeasurements(parameters);
  }

  public async getIndividualAnalysis(
    parameters: AnalysisSearchOptions
  ): Promise<Analysis[]> {
    const { result } = await businessDataClient.getIndividualAnalysis(
      parameters
    );
    return result;
  }

  public async computeIndividualAnalysis(
    parameters: IndividualAnalysisOptions
  ): Promise<void> {
    await businessDataClient.computeIndividualAnalysis(parameters);
  }

  public async aggregatedAnalysis(
    parameters: AggregatedAnalysisOptions
  ): Promise<Analysis> {
    return businessDataClient.aggregatedAnalysis(parameters);
  }

  public async computeTransports(
    perimeter: TransportPerimeter
  ): Promise<TransportProfile> {
    const profiles = await salesforceGatewayClient.fetchProfilEnergetiques([
      perimeter.prm,
    ]);

    const profile = profiles.find((profile) => profile.prm === perimeter.prm);
    if (!profile)
      throw new Error('Le profil énergétique est absent de Salesforce');

    const transportProfile = transportBudgetMapper.buildTransportProfile(
      perimeter,
      profile
    );

    const transportRequestParameters =
      transportBudgetMapper.buildTransportRequestParameters(profile, perimeter);

    await Promise.all([
      await enedisApiClient.computeTransport(
        transportRequestParameters,
        PowerTransportPropertyType.Current
      ),
      await enedisApiClient.optimizeTransport(transportRequestParameters),
    ]);

    return transportProfile;
  }

  public async importMeasurements(
    parameters: MeasurementsImportParameters,
    file: Blob
  ): Promise<void> {
    await businessDataClient.importMeasurements(parameters, file);
  }

  public async exportReadings(
    parameters: ExportReadingParameters
  ): Promise<{ blob: Blob; filename?: string }> {
    return businessDataClient.exportReadings(parameters);
  }
}

export default new BusinessDataApiService();
