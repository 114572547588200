import {
  ConcurrencyOptions,
  HttpClient,
} from '@Collectif-Energie/collective-lib';

import config from '../../config/config';

import { AxiosResponse, AxiosResponseHeaders } from 'axios';
import { MeasurementsImportParameters } from './business-data.interface';
import {
  AggregatedAnalysisOptions,
  Analysis,
  AnalysisSearchOptions,
  IndividualAnalysisOptions,
} from './dto/analysis.interface';
import {
  ExportMeasurementParameters,
  ExportReadingParameters,
} from './dto/measurement.interface';
import { FetchTransportPerimeter } from './dto/power-transport.interface';

export class BusinessDataClient extends HttpClient {
  constructor() {
    const headers = { 'x-api-key': config.businessData.apiKey };
    const options: ConcurrencyOptions = {
      maxConcurrency: 30,
      runsAllowedInDuration: 30,
      duration: 1,
    };

    super(options, `${config.businessData.baseUrl}/api/v1`, headers);
  }

  public async fetchTransports(
    perimeter: FetchTransportPerimeter
  ): Promise<any> {
    const { data } = await this.get('/power/transports/budgets', {
      params: perimeter,
    });

    return data;
  }

  public async downloadTemplateImportMeasurements(): Promise<{
    blob: Blob;
    filename?: string;
  }> {
    const res: AxiosResponse = await this.get('/power/measurements/template', {
      responseType: 'blob',
    });

    const filename = this.extractFilenameFromHeaders(res.headers);
    const blob = new Blob([res.data]);

    return { blob, filename };
  }

  public async exportMeasurements(
    parameters: ExportMeasurementParameters
  ): Promise<void> {
    await this.get('/power/measurements', {
      params: parameters,
      responseType: 'blob',
    });
  }

  private extractFilenameFromHeaders(
    headers: AxiosResponseHeaders
  ): string | undefined {
    let filename: string | undefined = undefined;

    const contentDisposition =
      headers['content-disposition'] || headers['Content-Disposition'];

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch?.length === 2) filename = fileNameMatch[1];
    }

    return filename;
  }

  public async aggregatedAnalysis(
    perimeter: AggregatedAnalysisOptions
  ): Promise<Analysis> {
    const { data } = await this.get('/power/analysis/aggregates', {
      params: perimeter,
    });

    return data;
  }

  public async getIndividualAnalysis(
    perimeter: AnalysisSearchOptions
  ): Promise<{ result: Analysis[] }> {
    const { data } = await this.get('/power/analysis', {
      params: perimeter,
    });

    return data;
  }

  public async computeIndividualAnalysis(
    perimeter: IndividualAnalysisOptions
  ): Promise<void> {
    await this.post('/power/analysis', perimeter);
  }

  public async importMeasurements(
    parameters: MeasurementsImportParameters,
    file: Blob
  ): Promise<void> {
    const formData = new FormData();
    formData.append('file', file);

    const headers = { 'Content-Type': 'multipart/form-data' };
    Object.assign(parameters, { origin: 'ENEDIS' });

    await this.put(`/power/measurements`, formData, {
      params: parameters,
      headers,
    });
  }

  public async exportReadings(
    parameters: ExportReadingParameters
  ): Promise<{ blob: Blob; filename?: string }> {
    const res: AxiosResponse = await this.get('/power/readings', {
      params: parameters,
      responseType: 'blob',
    });

    const filename = this.extractFilenameFromHeaders(res.headers);
    const blob = new Blob([res.data], {
      type: 'text/csv;charset=utf-8',
    });

    return { blob, filename };
  }
}

export default new BusinessDataClient();
