import type { AxiosResponse } from 'axios';

import { TransportRequestParameters } from '../../../server/modules/business-data/models/business-data.interface';
import { PowerTransportPropertyType } from '../business-data/business-data.interface';
import { ConcurrencyOptions, HttpClient } from '@Collectif-Energie/collective-lib';
import config from '../../config/config';

export class EnedisApiClient extends HttpClient {
  constructor() {
    const options: ConcurrencyOptions = {
      maxConcurrency: 30,
      runsAllowedInDuration: 30,
      duration: 1,
    };

    super(options, `${config.enedisApi.baseUrl}`);
  }

  public computeTransport = async (
    parameters: TransportRequestParameters,
    type: PowerTransportPropertyType
  ): Promise<AxiosResponse> => {
    parameters.transportPerimeter.type = type;

    const { data } = await this.post('/acheminement/budget', parameters, {
      withCredentials: true,
    });

    return data;
  };

  public optimizeTransport = async (
    parameters: TransportRequestParameters
  ): Promise<AxiosResponse> => {
    parameters.transportPerimeter.type = PowerTransportPropertyType.Optimize;

    const { data } = await this.post('/acheminement/optimize', parameters, {
      withCredentials: true,
    });

    return data;
  };
}

export default new EnedisApiClient();
