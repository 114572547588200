import {
  BudgetAmounts,
  BudgetAmountsAndCoefficient,
  PowerTransportBudget,
} from '@Collectif-Energie/budgetcomputer';
import {
  EndpointCommunicationCapability,
  EndpointConnection,
  NumberByTemporalClass,
  PowerConsumptionProfile,
  PowerEndpointCategory,
  TransportCode,
} from '@Collectif-Energie/collective-lib';

import {
  ConsumptionByTemporalClass,
  NbHoursByTemporalClass,
  PowerByTemporalClass,
} from '../../../server/modules/commons/types';
import { MeasurementByTemporalClass } from '../../../server/modules/measurements/models/measurement.type';

export enum FetchablePhysicalMagnitude {
  ActivePower = 'Active',
  InductiveReactivePower = 'InductiveReactive',
  CapacitiveReactivePower = 'CapacitiveReactive',
  ApparentPower = 'Apparent',
}

export enum FetchableMeasurementPace {
  Raw = 'RAW',
  PT10M = 'PT10M',
}

export interface FetchTransportPerimeter {
  prms: string[];
  margin: number;
  startDate: Date;
  endDate: Date;
}

export interface MeasurementsDownloadParameters {
  prm: string;
  startDate: Date;
  endDate: Date;
  physicalMagnitude?: FetchablePhysicalMagnitude;
  pace?: FetchableMeasurementPace;
}

export interface MeasurementsImportParameters {
  prm: string;
  physicalMagnitude: FetchablePhysicalMagnitude;
}

export interface TransportPerimeter {
  prm: string;
  margin: number;
  startDate: Date;
  endDate: Date;
  type?: PowerTransportPropertyType;
}

export interface PowerTransport {
  properties: PowerTransportProperties;
  budget: PowerTransportBudgetAndTax;
  analysis: PowerTransportAnalysis;
}

export interface PowerTransportProperties {
  prm: string;
  type: PowerTransportPropertyType;
  startDate: Date;
  endDate: Date;
  transportCode: TransportCode;
  endpointCategory: PowerEndpointCategory;
  endpointConnection: EndpointConnection;
  marginCoefficient: number;
  connectionMaxPower: number;
  consumptionProfile: PowerConsumptionProfile;
  subscribedPowers: NumberByTemporalClass;
  endpointTimeProgramming: string;
  supplierPriceOption: string;
  endpointCommunicationCapability: EndpointCommunicationCapability;
}

export interface PowerTransportBudgetAndTax extends BudgetAmounts {
  endpointServiceCostBudget: EndpointServiceCostBudget;
  transportBudget: PowerTransportBudget;
  ctaBudget: BudgetAmountsAndCoefficient;
}

export interface EndpointServiceCostBudget extends BudgetAmounts {
  subscribedPowerBudget: BudgetAmounts;
  transportCodeBudget: BudgetAmounts;
}

export interface PowerTransportAnalysis {
  prm: string;
  type: PowerTransportPropertyType;
  startDate: Date;
  endDate: Date;
  highestMeasurements: MeasurementByTemporalClass;
  overusePowers: PowerByTemporalClass;
  overuseHours: NbHoursByTemporalClass;
  consumptions: ConsumptionByTemporalClass;
  totalConsumption: number;
}

export interface TransportProfileError {
  prm: string;
  message: string;
}

export enum PowerTransportPropertyType {
  Current = 'CURRENT',
  Optimize = 'OPTIMIZE',
  Custom = 'CUSTOM',
}
